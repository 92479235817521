import './App.css';
import Layout from './components/Layout'
import React, { useEffect } from 'react';
function App() {
  useEffect(() => {
    const user = localStorage.getItem("UserInfo");
    if(user) {
      window.location.href="/dashboard"
    } else {
      window.location.href="/login"
    }

  })
  return (
    <Layout>
        <div className='absolute top-1/2 text-center w-full'>
          Loading...
        </div>
    </Layout>
  );
}

export default App;
