"use client"
import { signInWithEmailAndPassword }from 'firebase/auth';
import { auth } from '../firebase'
import React, { useState } from 'react';
import { Button } from '@mui/material';
const Login = () => {
  const [ isLogin, setIsLogin ] = useState(0)
  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')
  
  const setAuthen = async () => {
    setIsLogin(1)
    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      const user = userCredential.user;
      if(user) {
        await localStorage.setItem("UserInfo", JSON.stringify(user));
        console.log('User logged in:', userCredential.user);
        // Redirect to dashboard after successful login
        window.location.href = "/dashboard";
      }
    } catch (error) {
      console.error('Error logging in:', error);
      alert("Sorry, invalid username or password.\n"+ error.message);
    } finally {
      setIsLogin(0)
    }
  }

  return (
    <div className="w-full flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-6">🔐 Admin Login</h2>
        <form id="loginForm">
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-semibold text-gray-700">Email</label>
            <input type="email" id="email" className="mt-1 p-2 w-full border rounded" required
            value={username}
            placeholder='user@chance.in.th'
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="email"
            />
            <div id="emailError" className="text-red-500 text-xs"></div>
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-semibold text-gray-700">Password</label>
            <input type="password" id="password" className="mt-1 p-2 w-full border rounded" required
            value={password}
            placeholder='Password'
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            />
            <div id="passwordError" className="text-red-500 text-xs"></div>
          </div>
          <Button variant='contained' onClick={setAuthen} disabled={!username || !password || isLogin} type="button" className={`${!isLogin ? 'bg-blue-500' : 'bg-blue-300'} text-white p-2 rounded w-full`}>Login</Button>
        </form>
      </div>
    </div>
  )
}

export default Login;