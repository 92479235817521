import Navbar from '../components/Nav';
// import { app } from '../firebase'

const PDPA = () => {

  return (
    <>
      <Navbar/>
      <nav class="bg-gray-200 p-4 text-black font-bold">
        <h1 class="text-1xl">PDPA Audit</h1>
      </nav>
      <div class="p-6 max-w-6xl mx-auto">

      </div>
    </>
  )
}

export default PDPA;