import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { signOut } from "firebase/auth";

const Navbar = () => {
  const [user, setUser] = useState({});
  const getUser = () => {
    let user = localStorage.getItem('UserInfo');
    user = JSON.parse(user);
    if(user) {
      setUser(user)
    } else {
      window.location.href='/login'
      return;
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  const getCurrentPathName = (path) => {
    return path === window.location.pathname;
  }

  const logout = () => {

    if(window.confirm("Do you confirm logout?") === false) {
      return ;
    }

    localStorage.removeItem('UserInfo');
    // Assuming there's a function to logout from Firebase in your app
    // This is a placeholder for that function
    signOut(auth).then(() => {
      console.log('Logged out successfully');
      window.location.href="/login"
    }).catch((error) => {
      console.error('Error logging out:', error);
    });
  }

  return (
    <>
      <nav className="bg-[#083A6B] p-4 text-white justify-between flex flex-rows shadow-xl">
        <div className="space-x-8 flex flex-rows items-center">
          <img src="/images/Logo-w.png" alt="Chance logo" width={100}/>
          <div className="gap-6 flex flex-rows">
              <a href="/dashboard" className={`text-md ${getCurrentPathName('/dashboard') ? 'text-white underline' : 'text-gray-200'}`}>หน้าแรก</a>
              <a href="/users" className={`text-md ${getCurrentPathName('/users') ? 'text-white underline' : 'text-gray-200'}`}>ผู้ใช้งาน</a>
              <a href="/reports" className={`text-md ${getCurrentPathName('/reports') ? 'text-white underline' : 'text-gray-200'}`}>ปัญหา</a>
              {/* <a href="/pdpa" className={`text-md ${getCurrentPathName('/pdpa') ? 'text-white underline' : 'text-gray-200'}`}>PDPA</a> */}
              <a href="/category" className={`text-md ${getCurrentPathName('/category') ? 'text-white underline' : 'text-gray-200'}`}>หมวดหมู่</a>
          </div>
        </div>
        <div className="flex flex-col self-center items-end">
          <p>{user.email}</p>
          <button onClick={logout}>ออกจากระบบ 🚪</button>
        </div>
      </nav>
    </>
  )
}
export default Navbar;