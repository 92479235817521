import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import Login from './pages/Login'
import Users from './pages/Users'
import Dashboard from './pages/Dashboard';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './ErrorPage'
import Category from './pages/Category';
import PDPA from './pages/PDPA'
import Reports from './pages/Reports'

const router = createBrowserRouter([
  { path: "/", element: <App/>, errorElement: <ErrorPage/> },
  { path: "/login", element: <Login/> },
  { path: "/dashboard", element: <Dashboard/> },
  { path: "/users", element: <Users/> },
  { path: "/reports", element: <Reports/> },
  { path: "/pdpa", element: <PDPA/> },
  { path: "/category", element: <Category/> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
