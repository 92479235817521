import React from 'react';

document.title = 'Chance - โอกาส'

const Layout =({children}) =>{
  
  return(
      <main className="w-full h-screen bg-gray-100">{children}</main>
  )
}

export default Layout;