const ErrorPage = () => {
  return (
    <div className="flex h-screen w-full justify-center items-center bg-[#083A6B] flex-col text-white space-y-8">
      <img src="/images/logo.png" alt="Chance Logo" />
      <h1>😩 Sorry!, 404 Page not found!</h1>
      <button onClick={() => {
        window.location.href="/"
      }}
      className="bg-white text-black py-2 px-6 rounded"
      >back to login</button>
    </div>
  )
}

export default ErrorPage;