
const firebaseConfig = {
  apiKey: "AIzaSyDWGChS5svyECrIhgrCPblf4RtZK8-zW5s",
  authDomain: "chance-d6d69.firebaseapp.com",
  databaseURL: "https://chance-d6d69-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "chance-d6d69",
  storageBucket: "chance-d6d69.appspot.com",
  messagingSenderId: "768954563354",
  appId: "1:768954563354:web:8a1c03ef4e805c0e674aa3",
  measurementId: "G-YQ4YZTT604"
};

export default firebaseConfig;