import { useState, useEffect } from 'react';
import Navbar from '../components/Nav';
import { app } from '../firebase';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// ลงทะเบียน Chart.js components สำหรับ Bar Chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const STATUS_MAPPING = {
  SUMMITED: { label: 'แจ้งปัญหา', color: 'text-gray-800' },
  REVIEWING: { label: 'กำลังตรวจสอบ', color: 'text-yellow-800' },
  APPROVED: { label: 'ได้รับปัญหา', color: 'text-blue-800' },
  REJECT: { label: 'ถูกยกเลิก', color: 'text-red-800' },
  IN_PROGRESS: { label: 'กำลังดำเนินการ', color: 'text-purple-800' },
  FORWARD: { label: 'ส่งต่อ', color: 'text-indigo-800' },
  DONE: { label: 'สำเร็จ', color: 'text-green-800' }
};

const TIME_RANGES = {
  '7D': { label: '7 วัน', days: 7 },
  '1M': { label: '1 เดือน', days: 30 },
  '3M': { label: '3 เดือน', days: 90 },
  '1Y': { label: '1 ปี', days: 365 }
};

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [reportCount, setReportCount] = useState(0);
  const [statusCounts, setStatusCounts] = useState({
    SUMMITED: 0,
    REVIEWING: 0,
    APPROVED: 0,
    REJECT: 0,
    IN_PROGRESS: 0,
    FORWARD: 0,
    DONE: 0
  });
  const [dailyReports, setDailyReports] = useState({
    labels: [],
    datasets: []
  });
  const [selectedRange, setSelectedRange] = useState('7D');
  const [reportsData, setReportsData] = useState([]);

  const generateDateRange = (days) => {
    const dates = {};
    const today = new Date();
    
    for (let i = days - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      const dateStr = date.toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
      dates[dateStr] = 0;
    }
    return dates;
  };

  const updateChartData = (days) => {
    const dailyData = generateDateRange(days);
    
    reportsData.forEach((report) => {
      const createdAt = report.created_at?.toDate();
      if (createdAt) {
        const dateStr = createdAt.toLocaleDateString('th-TH', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        if (dailyData.hasOwnProperty(dateStr)) {
          dailyData[dateStr]++;
        }
      }
    });

    setDailyReports({
      labels: Object.keys(dailyData),
      datasets: [
        {
          label: 'จำนวนการแจ้งปัญหา',
          data: Object.values(dailyData),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderColor: 'rgb(53, 162, 235)',
          borderWidth: 1,
        }
      ]
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore(app);
      
      const usersSnapshot = await getDocs(collection(db, 'users'));
      setUserCount(usersSnapshot.size);
      
      const reportsRef = collection(db, 'reports');
      const reportsSnapshot = await getDocs(reportsRef);
      setReportCount(reportsSnapshot.size);

      const counts = { ...statusCounts };
      const reports = [];
      
      reportsSnapshot.forEach((doc) => {
        const data = doc.data();
        reports.push(data);
        
        const status = data.status;
        if (counts.hasOwnProperty(status)) {
          counts[status]++;
        }
      });

      setStatusCounts(counts);
      setReportsData(reports);
      updateChartData(TIME_RANGES[selectedRange].days);
    };

    fetchData();
  }, []);

  useEffect(() => {
    updateChartData(TIME_RANGES[selectedRange].days);
  }, [selectedRange, reportsData]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `สถิติการแจ้งปัญหา ${TIME_RANGES[selectedRange].label}ล่าสุด`,
        font: {
          size: 16
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          font: {
            size: 12
          }
        },
        title: {
          display: true,
          text: 'จำนวนการแจ้งปัญหา',
          font: {
            size: 12
          }
        }
      },
      x: {
        ticks: {
          font: {
            size: 12
          }
        },
        title: {
          display: true,
          text: 'วันที่',
          font: {
            size: 12
          }
        }
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold">Total Users</h3>
            <p className="text-8xl font-bold text-blue-500">{userCount}</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold">Total Reports</h3>
            <p className="text-8xl font-bold text-blue-500">{reportCount}</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow col-span-2">
            <h3 className="text-xl font-semibold mb-4">สถานะรายงาน</h3>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(STATUS_MAPPING).map(([status, { label, color }]) => (
                <div key={status} className="flex justify-between items-center p-2 rounded-lg bg-gray-50">
                  <span className="text-gray-600">{label}:</span>
                  <span className={`font-semibold ${color}`}>
                    {statusCounts[status]}
                  </span>
                </div>
              ))}
            </div>
          </div>
          
          <div className="bg-white p-4 rounded-lg shadow col-span-full">
            <div className="flex justify-end mb-4">
              <div className="inline-flex rounded-md shadow-sm" role="group">
                {Object.entries(TIME_RANGES).map(([key, { label }]) => (
                  <button
                    key={key}
                    type="button"
                    onClick={() => setSelectedRange(key)}
                    className={`px-4 py-2 text-sm font-medium border ${
                      selectedRange === key
                        ? 'bg-blue-500 text-white border-blue-500'
                        : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                    } ${
                      key === '7D' ? 'rounded-l-lg' : ''
                    } ${
                      key === '1Y' ? 'rounded-r-lg' : ''
                    }`}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
            <div className="h-[400px]">
              <Bar options={chartOptions} data={dailyReports} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard;