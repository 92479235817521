import Navbar from '../components/Nav';
import { app } from '../firebase';
import { getFirestore, collection, getDocs, doc, updateDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredReports, setFilteredReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportsPerPage] = useState(20);
  const db = getFirestore(app);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);

  const STATUS_MAPPING = {
    SUMMITED: { label: 'แจ้งปัญหา', color: 'bg-gray-100 text-gray-800' },
    REVIEWING: { label: 'กำลังตรวจสอบ', color: 'bg-yellow-100 text-yellow-800' },
    APPROVED: { label: 'ได้รับปัญหา', color: 'bg-blue-100 text-blue-800' },
    REJECT: { label: 'ถูกยกเลิก', color: 'bg-red-100 text-red-800' },
    IN_PROGRESS: { label: 'กำลังดำเนินการ', color: 'bg-purple-100 text-purple-800' },
    FORWARD: { label: 'ส่งต่อ', color: 'bg-indigo-100 text-indigo-800' },
    DONE: { label: 'สำเร็จ', color: 'bg-green-100 text-green-800' }
  };

  // เพิ่ม function สำหรับจัดการ localStorage
  const saveReportsToLocalStorage = (reportsData) => {
    try {
      localStorage.setItem('reports', JSON.stringify(reportsData));
      localStorage.setItem('reportsLastUpdated', new Date().toISOString());
    } catch (error) {
      console.error("Error saving to localStorage:", error);
    }
  };

  const getReportsFromLocalStorage = () => {
    try {
      const reports = localStorage.getItem('reports');
      return reports ? JSON.parse(reports) : null;
    } catch (error) {
      console.error("Error reading from localStorage:", error);
      return null;
    }
  };

  useEffect(() => {
    if (shouldRefreshData()) {
      fetchReports();
    } else {
      const cachedReports = getReportsFromLocalStorage();
      if (cachedReports) {
        setReports(cachedReports);
        setFilteredReports(cachedReports);
        setIsLoading(false);
      } else {
        fetchReports();
      }
    }
  }, []);

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      
      // ตรวจสอบข้อมูลใน localStorage ก่อน
      // const cachedReports = getReportsFromLocalStorage();
      // if (cachedReports) {
      //   setReports(cachedReports);
      //   setFilteredReports(cachedReports);
      //   setIsLoading(false);
      //   return;
      // }

      const querySnapshot = await getDocs(collection(db, "reports"));
      const reportsData = [];
      
      for (const docSnapshot of querySnapshot.docs) {
        const reportData = { id: docSnapshot.id, ...docSnapshot.data() };
        
        if (reportData.owner_id) {
          const userDoc = await getDoc(doc(db, "users", reportData.owner_id));
          if (userDoc.exists()) {
            reportData.ownerName = userDoc.data().name || 'ไม่ระบุชื่อ';
          }
        }
        
        reportsData.push(reportData);
      }
      
      // บันทึกข้อมูลลง localStorage
      saveReportsToLocalStorage(reportsData);
      
      setReports(reportsData);
      setFilteredReports(reportsData);
    } catch (error) {
      console.error("Error fetching reports: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async () => {
    if (!selectedReport || !newStatus) return;

    try {
      const reportRef = doc(db, "reports", selectedReport.id);
      await updateDoc(reportRef, {
        status: newStatus,
        updatedAt: new Date()
      });

      // อัพเดทข้อมูลใน state และ localStorage
      const updatedReports = reports.map(report => {
        if (report.id === selectedReport.id) {
          return {
            ...report,
            status: newStatus,
            updatedAt: new Date()
          };
        }
        return report;
      });

      setReports(updatedReports);
      setFilteredReports(updatedReports);
      saveReportsToLocalStorage(updatedReports);

      setIsModalOpen(false);
      setSelectedReport(null);
      setNewStatus('');
      alert('อัพเดตสถานะสำเร็จ');
    } catch (error) {
      console.error("Error updating status: ", error);
      alert('เกิดข้อผิดพลาดในการอัพเดตสถานะ');
    }
  };

  const openEditModal = (report) => {
    setSelectedReport(report);
    setNewStatus(report.status || '');
    setIsModalOpen(true);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    
    const filtered = reports.filter(report => {
      const matchesSearch = report.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                           report.ownerName?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = !selectedStatus || report.status === selectedStatus;
      return matchesSearch && matchesStatus;
    });
    
    setFilteredReports(filtered);
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    
    const filtered = reports.filter(report => {
      const matchesSearch = report.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                           report.ownerName?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = !status || report.status === status;
      return matchesSearch && matchesStatus;
    });
    
    setFilteredReports(filtered);
  };

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(indexOfFirstReport, indexOfLastReport);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const Pagination = ({ reportsPerPage, totalReports, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalReports / reportsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-center mt-4">
        <nav>
          <ul className="flex space-x-2">
            {pageNumbers.map(number => (
              <li key={number}>
                <button
                  onClick={() => paginate(number)}
                  className={`px-3 py-1 rounded ${
                    currentPage === number
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 hover:bg-gray-300'
                  }`}
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    );
  };

  // แก้ไขฟังก์ชัน shouldRefreshData ให้เช็คทุก 10 นาที
  const shouldRefreshData = () => {
    const lastUpdated = localStorage.getItem('reportsLastUpdated');
    if (!lastUpdated) return true;

    const now = new Date();
    const lastUpdateTime = new Date(lastUpdated);
    const minutesSinceLastUpdate = (now - lastUpdateTime) / (1000 * 60);

    // refresh ข้อมูลถ้าผ่านไปมากกว่า 10 นาที
    return minutesSinceLastUpdate > 10;
  };

  // เพิ่ม useEffect สำหรับ auto refresh
  useEffect(() => {
    // ตั้งเวลาเรียก fetchReports ทุก 10 นาที
    const intervalId = setInterval(() => {
      if (shouldRefreshData()) {
        fetchReports();
      }
    }, 10 * 60 * 1000); // 10 นาที * 60 วินาที * 1000 มิลลิวินาที

    // Cleanup interval เมื่อ component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array เพื่อให้ทำงานครั้งเดียวตอน mount

  // เพิ่มฟังก์ชันสำหรับลบรายการ
  const handleDelete = async (reportId) => {
    // ถามยืนยันก่อนลบ
    if (!window.confirm('คุณแน่ใจหรือไม่ที่จะลบรายการนี้?')) {
      return;
    }

    try {
      // ลบข้อมู��จาก Firestore
      await deleteDoc(doc(db, "reports", reportId));

      // อัพเดท state และ localStorage
      const updatedReports = reports.filter(report => report.id !== reportId);
      setReports(updatedReports);
      setFilteredReports(updatedReports);
      saveReportsToLocalStorage(updatedReports);

      alert('ลบรายการสำเร็จ');
    } catch (error) {
      console.error("Error deleting report: ", error);
      alert('เกิดข้อผิดพลาดในการลบรายการ');
    }
  };

  const handleManualRefresh = async () => {
    setIsRefreshing(true);
    await fetchReports();
    setIsRefreshing(false);
  };

  return (
    <>
      <Navbar/>
      <nav className="bg-gray-200 p-4 text-black font-bold">
        <div className="flex justify-between items-center">
          <h1 className="text-1xl">Reports Management</h1>
          <button
            onClick={() => handleManualRefresh()}
            disabled={isRefreshing}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300"
          >
            {isRefreshing ? (
              <>
                <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>กำลังอัพเดท...</span>
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
                <span>อัพเดทข้อมูล</span>
              </>
            )}
          </button>
        </div>
      </nav>
      <div className="p-6 max-w-6xl mx-auto">
        <div className="mb-4 flex gap-4">
          <select
            className="p-2 border rounded w-48"
            value={selectedStatus}
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="">ทุกสถานะ</option>
            {Object.entries(STATUS_MAPPING).map(([key, { label }]) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="🔍 ค้นหาจากชื่อเรื่องหรือชื่อผู้แจ้ง..."
            className="w-full p-2 border rounded"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        
        {isLoading ? (
          <div className="text-center py-10">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-blue-500 border-t-transparent"></div>
            <p className="mt-2 text-gray-600">กำลังโหลดข้อมูล...</p>
          </div>
        ) : (
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-300 bg-gray-100">ภาพประกอบ</th>
                <th className="px-6 py-3 border-b border-gray-300 bg-gray-100">เรื่อง</th>
                <th className="px-6 py-3 border-b border-gray-300 bg-gray-100">ผู้แจ้งปัญหา</th>
                <th className="px-6 py-3 border-b border-gray-300 bg-gray-100">สถานะ</th>
                <th className="px-6 py-3 border-b border-gray-300 bg-gray-100">วันที่รายงาน</th>
                <th className="px-6 py-3 border-b border-gray-300 bg-gray-100"></th>
              </tr>
            </thead>
            <tbody>
              {currentReports.map((report) => (
                <tr key={report.id}>
                  <td className="px-6 py-4 border-b border-gray-300">
                    {report.evidences[0]?.asset_path ? (
                      <img 
                        src={report.evidences[0]?.asset_path} 
                        alt="รูปรายงาน" 
                        className="w-20 h-20 object-cover rounded"
                      />
                    ) : (
                      <span className="text-gray-400">{report.evidences[0]?.asset_path}</span>
                    )}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-300 text-blue-500">
                    <a href={`https://map.chance.in.th/detail/${report.id}`} target='_blank' className="flex items-center gap-2 text-gray-800 hover:text-blue-600">
                      {report.title || 'ไม่ระบุ'} 
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" style={{fill: 'rgba(0, 0, 0, 1)', transform: '', msFilter: ''}}><path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path><path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path></svg>
                    </a>
                  </td>
                  <td className="px-6 py-4 border-b border-gray-300">
                    {report.ownerName || 'ไม่ระบุ'}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-300">
                    <div className="flex items-center gap-2">
                      <span className={`px-2 py-1 rounded-full text-sm ${STATUS_MAPPING[report.status]?.color || 'bg-gray-100 text-gray-800'}`}>
                        {STATUS_MAPPING[report.status]?.label || 'ไม่ระบุสถานะ'}
                      </span>
                      <button 
                        onClick={() => openEditModal(report)}
                        className="p-1 hover:bg-gray-100 rounded-full"
                        title="แก้ไขสถานะ"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill: 'rgba(0, 0, 0, 1)', transform: '', msFilter: ''}}><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z"></path></svg>
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 border-b border-gray-300">
                    {report.created_at ? new Date(report.created_at.seconds * 1000).toLocaleDateString('th-TH', {
                      year: 'numeric',
                      month: 'long', 
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    }) : 'ไม่���ะบุ'}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-300">
                    <button 
                        onClick={() => handleDelete(report.id)}
                        className="p-1 hover:bg-gray-100 rounded-full text-red-500"
                        title="ลบรายการ"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill: 'rgba(0, 0, 0, 1)', transform: '', msFilter: ''}}><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path></svg>
                      </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Pagination
          reportsPerPage={reportsPerPage}
          totalReports={filteredReports.length}
          paginate={paginate}
          currentPage={currentPage}
        />

        {/* Modal สำหรับแก้ไขสถานะ */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-lg font-semibold mb-4">แก้ไขสถานะ</h2>
              <div className="mb-4">
                <p className="text-sm text-gray-600 mb-2">เรื่อง: {selectedReport?.title}</p>
                <select 
                  className="w-full p-2 border rounded"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <option value="">เลือกสถานะ</option>
                  {Object.entries(STATUS_MAPPING).map(([key, { label }]) => (
                    <option key={key} value={key}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end gap-2">
                <button 
                  className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                  onClick={() => setIsModalOpen(false)}
                >
                  ยกเลิก
                </button>
                <button 
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleStatusChange}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Reports;