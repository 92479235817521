import Navbar from '../components/Nav';
import { app } from '../firebase';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

const Category = () => {
  const [tags, setTags] = useState([]);
  const [error, setError] = useState('');
  const db = getFirestore(app);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "tags"));
        const tagsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const uniqueTags = tagsData.reduce((unique, current) => {
          const exists = unique.find(
            item => item.name.toLowerCase() === current.name.toLowerCase()
          );
          if (!exists) {
            unique.push(current);
          }
          return unique;
        }, []);

        uniqueTags.sort((a, b) => a.name.localeCompare(b.name));
        
        setTags(uniqueTags);
      } catch (error) {
        console.error("Error fetching tags: ", error);
        setError('เกิดข้อผิดพลาดในการดึงข้อมูล');
      }
    };

    fetchTags();
  }, []);

  return (
    <>
      <Navbar/>
      <nav className="bg-gray-200 p-4 text-black font-bold">
        <h1 className="text-1xl">Category Management</h1>
      </nav>
      <div className="p-6 max-w-6xl mx-auto">

        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-300 bg-gray-100">Tag Name</th>
              <th className="px-6 py-3 border-b border-gray-300 bg-gray-100">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tags.map((tag) => (
              <tr key={tag.id}>
                <td className="px-6 py-4 border-b border-gray-300">{tag.name}</td>
                <td className="px-6 py-4 border-b border-gray-300">
                  <button className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Edit</button>
                  <button className="bg-red-500 text-white px-4 py-2 rounded">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Category;