import { useEffect, useState } from 'react';
import Navbar from '../components/Nav';
import { app } from '../firebase';
import { getFirestore, collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [editForm, setEditForm] = useState({
    username: '',
    email: '',
    phone: '',
    photoURL: ''
  });

  // เพิ่ม state สำหรับ pagination
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 20;

  const db = getFirestore(app);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUsers(usersData);
        setFilteredUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [db]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    
    const filtered = users.filter(user => 
      user.username?.toLowerCase().includes(searchTerm) ||
      user.email?.toLowerCase().includes(searchTerm) ||
      user.phone?.toLowerCase().includes(searchTerm)
    );
    
    setFilteredUsers(filtered);
  };

  const handleDelete = async (userId) => {
    try {
      await deleteDoc(doc(db, "users", userId));
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleEdit = (user) => {
    setEditingUser(user.id);
    setEditForm({
      username: user.username,
      email: user.email,
      phone: user.phone,
      photoURL: user.photoURL
    });
  };

  const handleUpdate = async () => {
    try {
      const userRef = doc(db, "users", editingUser);
      await updateDoc(userRef, editForm);
      
      // อัพเดท state ของ users
      setUsers(users.map(user => 
        user.id === editingUser 
          ? { ...user, ...editForm }
          : user
      ));
      
      // รีเซ็ตฟอร์มและสถานะการแก้ไข
      setEditingUser(null);
      setEditForm({ username: '', email: '', phone: '', photoURL: '' });
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleCancel = () => {
    setEditingUser(null);
    setEditForm({ username: '', email: '', phone: '', photoURL: '' });
  };

  // เพิ่มฟังก์ชันคำนวณข้อมูลที่จะแสดงในหน้าปัจจุบัน
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // เพิ่มฟังก์ชันเปลี่ยนหน้า
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Navbar/>
      <nav className="bg-gray-200 p-4 text-black font-bold">
        <h1 className="text-1xl">Users Management</h1>
      </nav>
      <div className="p-6 max-w-6xl mx-auto">
        <div className="mb-4">
          <input
            type="text"
            placeholder="🔍 ค้นหาผู้ใช้..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <table className="min-w-full bg-white rounded-lg shadow">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 text-left">รูปภาพ</th>
              <th className="py-2 px-4 text-left">Username</th>
              <th className="py-2 px-4 text-left">Email</th>
              <th className="py-2 px-4 text-left">Phone</th>
              <th className="py-2 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user) => (
              <tr key={user.id} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">
                  {editingUser === user.id ? (
                    <input
                      type="text"
                      value={editForm.profile_image || ''}
                      onChange={(e) => setEditForm({...editForm, profile_image: e.target.value})}
                      className="border rounded px-2 py-1 w-full"
                      placeholder="URL รูปภาพ"
                    />
                  ) : (
                    <img 
                      src={user.profile_image || 'https://i.pravatar.cc/150?u=' + user.email} 
                      alt={user.display_name}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  )}
                </td>
                <td className="py-2 px-4">
                  {editingUser === user.id ? (
                    <input
                      type="text"
                      value={editForm.display_name}
                      onChange={(e) => setEditForm({...editForm, display_name: e.target.value})}
                      className="border rounded px-2 py-1 w-full"
                    />
                  ) : (
                    user.display_name
                  )}
                </td>
                <td className="py-2 px-4">
                  {editingUser === user.id ? (
                    <input
                      type="email"
                      value={editForm.email}
                      onChange={(e) => setEditForm({...editForm, email: e.target.value})}
                      className="border rounded px-2 py-1 w-full"
                    />
                  ) : (
                    user.email
                  )}
                </td>
                <td className="py-2 px-4">
                  {editingUser === user.id ? (
                    <input
                      type="text"
                      value={editForm.mobile_phone_no}
                      onChange={(e) => setEditForm({...editForm, mobile_phone_no: e.target.value})}
                      className="border rounded px-2 py-1 w-full"
                    />
                  ) : (
                    user.mobile_phone_no
                  )}
                </td>
                <td className="py-2 px-4">
                  {editingUser === user.id ? (
                    <>
                      <button 
                        className="bg-green-500 text-white px-3 py-1 rounded mr-2 hover:bg-green-600"
                        onClick={handleUpdate}
                      >
                        บันทึก
                      </button>
                      <button 
                        className="bg-gray-500 text-white px-3 py-1 rounded hover:bg-gray-600"
                        onClick={handleCancel}
                      >
                        ยกเลิก
                      </button>
                    </>
                  ) : (
                    <>
                      <button 
                        className="bg-blue-500 text-white px-3 py-1 rounded mr-2 hover:bg-blue-600"
                        onClick={() => handleEdit(user)}
                      >
                        แก้ไข
                      </button>
                      <button 
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                        onClick={() => handleDelete(user.id)}
                      >
                        ลบ
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* เพิ่ม Pagination Controls */}
        <div className="mt-4 flex justify-center">
          <nav className="flex items-center gap-2">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-3 py-1 rounded ${
                currentPage === 1 
                  ? 'bg-gray-300 cursor-not-allowed' 
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            >
              ก่อนหน้า
            </button>
            
            {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }).map((_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`px-3 py-1 rounded ${
                  currentPage === index + 1
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                {index + 1}
              </button>
            ))}
            
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}
              className={`px-3 py-1 rounded ${
                currentPage === Math.ceil(filteredUsers.length / usersPerPage)
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            >
              ถัดไป
            </button>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Users;